<template>
  <section id="student-assessment" v-if="!loading">
    <HeaderSearch :data="gradingState" @updateStatus="updateStatus" :user_list="gradingUsers.user_list" :sum_score="gradingUsers.sum_score"/>
    <v-sheet class="custom-border border my-7">
      <Question v-for="(item, i) in gradingState.question_answers" :key="i" :i="i" :data="item" @save="save" :loading="saveLoading"/>
    </v-sheet>
  </section>
  <circular v-else/>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import HeaderSearch from "../../components/teacher/grading/HeaderSearch.vue";
import Question from "../../components/teacher/grading/Question.vue";

export default {
  components: {
    HeaderSearch,
    Question,
  },
  methods: {
    ...mapActions('instructor', ['updateGradingAction', 'getGradingAction', 'getGradingStudent']),

    ...mapMutations(['alertMutation']),

    updateStatus(e){
      this.grading['status'] = e
    },

    async save(){
      this.saveLoading = true
      this.gradingState.question_answers.forEach(item => {
        this.grading.question_answer_id.push(item.id)
        this.grading.scores.push(item.score ? item.score : 0)
        this.grading.comments.push(item.question_answer_feedback ? item.question_answer_feedback.comment : null)
      })
      this.updateGradingAction({
        user_id: this.gradingState.score.user.id,
        assessment_id: this.gradingState.id,
        question_answer_id: this.grading.question_answer_id,
        score: this.grading.scores,
        checked: this.grading.status == null ? this.gradingState.score.checked ? true : false : this.grading.status,
        comment: this.grading.comments
      }).then(()=>{
        this.saveLoading = false
        this.alertMutation({
          show: true,
          text: `Successfully Updated for ${this.gradingState.score.user.first_name} ${this.gradingState.score.user.last_name}`,
          type: "success"
        })
      })
    }
  },
  mounted(){
    this.getGradingAction({
        assessment_id: this.$route.params.id,
        user_id: this.$route.params.user_id,
      }).then(()=>{
        this.getGradingStudent(this.gradingState.course_id).then(() => {
          this.loading = false
        })
      })
  },
  computed: {

    ...mapState('instructor', {
      gradingState: (state) => state.grading,
      gradingUsers(state) {
        var assessment = {}
        var user_list = []
        var sum_score = 0
        state.gradingUsers.forEach(item => {
          assessment = {
            id: item.id,
            title: item.title?item.title: 'Untitled', 
            users: [],
          }
          if(item.assessment_scores.length > 0) {
          item.assessment_scores.forEach(scores => {
              assessment.users.push({
                assessment_id: item.id,
                user_id: scores.user_id,
                user: `${scores.user.first_name} ${scores.user.last_name}`,
              })
              
              if(scores.user_id === state.grading.score.user_id) {
                if(item.id == this.$route.params.id) {
                  sum_score = item.assessment_questions_sum_score
                }
              }
          })

          }
          user_list.push(assessment)
        })

        return { user_list: user_list, sum_score: sum_score }
      }
    }),
  },

  data: () => ({
    grading: {
      status: null,
      answers: [],
      question_answer_id: [],
      scores: [],
      comments: []
    },
    saveLoading: false,
    sum_score: 0,
    loading: true
  }),
};
</script>

<style></style>
